import './App.css';
import MainPage from "./components/app/MainPage";


function App() {
  return (

    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
