import React from 'react';

const SubPartTitle = ({ title }) => {
  return (
    <div>
      <h1 className="sub-part-title">
        {title}
      </h1>
    </div>
  );
};

export default SubPartTitle;
