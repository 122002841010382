import AttackBuilder from "../attack/AttackBuilder";
import Opponent from "../opponent/Opponent";

function LeftPart() {
    return (
        <div className="side-part">
                <AttackBuilder/>
                <Opponent/>
        </div>
    );
}

export default LeftPart;