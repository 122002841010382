import React, { useState, useContext } from 'react';
import DamageLine from './DamageLine';
import SpellPicker from './SpellPicker';
import SubPartTitle from '../common_use/SubPartTitle';
import { DataContext } from "../common_use/DataContext";
import DamageLineStats from './DamageLineStats';
import Attack from '../attack/Attack';

function AttackBuilder() {
    const [attack, setAttack] = useState(new Attack([new DamageLineStats()]));
    const { sharedData, setSharedData } = useContext(DataContext);

    const setAttackAndSharedData = (updatedAttack) => {
        setAttack(updatedAttack);
        setSharedData({
            ...sharedData,
            attack: updatedAttack
        });
    }

    const updateField = (id, updatedLine) => {
        const updatedStats = attack.damageLinesStats.map((damageLineStats, index) => {
            return index === id ? updatedLine : damageLineStats;
        });

        // Remove any field except the last one if all amounts are empty
        const filteredStats = updatedStats.filter((stats, index) =>
            stats.fromAmount || stats.toAmount || stats.fromAmountCrit || stats.toAmountCrit || index === updatedStats.length - 1
        );

        // If the last field is filled, add a new empty field
        if (filteredStats[filteredStats.length - 1].fromAmount || filteredStats[filteredStats.length - 1].toAmount || filteredStats[filteredStats.length - 1].fromAmountCrit || filteredStats[filteredStats.length - 1].toAmountCrit) {
            filteredStats.push(new DamageLineStats());
        }

        const newAttack = attack.clone();
        newAttack.damageLinesStats = filteredStats;

        setAttackAndSharedData(newAttack);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <SubPartTitle title="Attack" />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <SpellPicker onPrefill={setAttackAndSharedData} />
                <h4>Damages</h4>
                {attack.damageLinesStats.map((stats, index) => (
                    <div key={index} style={{ display: "flex" }}>
                        <DamageLine
                            id={index}
                            stats={stats}
                            onAmountChange={updateField}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AttackBuilder;
