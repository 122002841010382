import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import { useContext } from 'react';
import { DataContext } from "../common_use/DataContext";
import Build from '../player_and_build/Build';

const REFRESH_RATE = 1000 / 5; // 5 times per second
const BACKEND_METHOD = 'POST';

function SearchBuildButton({ text }) {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { sharedData, setSharedData } = useContext(DataContext);
  const sendBuildRequest = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/evaluate-attack`, {
        method: BACKEND_METHOD,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        ...BACKEND_METHOD === 'POST' && { body: sharedData.attack.toRoxxBuilderFormatJSON() },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error while sending build request to backend', error);
    }
  }

  const resetProgress = (duration) => {
    if (!isLoading) {
      setIsLoading(true);
      setProgress(0);
      const startTime = Date.now();
      const interval = setInterval(() => {
        var curProg = (Date.now() - startTime) / duration * 100;
        if (curProg >= 100) {
          clearInterval(interval);
          setIsLoading(false);
          curProg = 100;
        }
        setProgress(curProg);
      }, REFRESH_RATE);
    }
  }

  const handleClick = async () => {
    const JSONResponse = await sendBuildRequest();
    if (JSONResponse.status === "success") {
      setSharedData({
        ...sharedData,
        build: Build.fromRoxxBuilderFormatJSON(JSONResponse.data),
      });
    } else if (JSONResponse.status === "pending") {
      resetProgress(JSONResponse.estimated_wait_time);
    } else {
      console.error(`Unexpected status from evaluate POST request: ${JSONResponse.status}`);
    }
  };

  return (
    <div className="button-container">
      {isLoading ? (
        <div>
          <ProgressBar value={progress} />
        </div>
      ) : (
        <button className="search-build-button" onClick={handleClick}>
          {text}
        </button>
      )}
    </div>
  );
}

export default SearchBuildButton;
