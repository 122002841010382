import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Attack from './Attack';
import DamageLineStats from './DamageLineStats';
import { useContext } from 'react';
import { DataContext } from "../common_use/DataContext";

const spellDataURL = `${process.env.REACT_APP_BACKEND_URL}/api/attacks`;

/* eslint-disable */
function SpellPicker({ onPrefill }) {
    const CUSTOM_ATTACK = new Attack([new DamageLineStats()]);
    const CUSTOM_SPELL = {
        id: 0,
        names: {
            "fr": "Personalisé",
            "en": "Custom",
        },
        breedId: -1,
    };

    const [spellList, setSpellList] = useState([]);
    const [selectedSpell, setSelectedSpell] = useState(CUSTOM_SPELL);
    const [attackMap, setAttackMap] = useState(new Map([[0, [CUSTOM_ATTACK]]]));
    const { sharedData } = useContext(DataContext);

    useEffect(() => {
        const fetchAttackMapAndOptions = async () => {
            try {
                const response = await fetch(spellDataURL);
                const responseJSON = await response.json();

                if (responseJSON.status === "succes") {
                    const data = responseJSON.data;
                    var mappedList = data.flatMap(spellVariant => spellVariant.spells.map(spell => ({ ...spell, breedId: spellVariant.breedId })));
                    const map = Attack.fromRoxxBuilderFormatJSON(mappedList);
                    console.log("the map of attacks", map);
                    console.log("spell list", mappedList);
                    mappedList = mappedList.filter(spell => map.has(spell.id) && map.get(spell.id).length > 0);
                    console.log("mapped list", mappedList);
                    mappedList.unshift(CUSTOM_SPELL);
                    setSelectedSpell(CUSTOM_SPELL);
                    setSpellList(mappedList);
                    map.set(CUSTOM_SPELL.id, [CUSTOM_ATTACK]);
                    console.log(map);
                    setAttackMap(map);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchAttackMapAndOptions().then();

        setSelectedSpell(CUSTOM_SPELL);
    }, []);

    useEffect(() => {
        console.log("BREED ID TRIGGER spell list", spellList, selectedSpell);
        /* eslint-disable-next-line eqeqeq */
        if (selectedSpell && spellList && spellList.find(spell => spell.breedId == sharedData.player.breedId)){
        /* eslint-disable-next-line eqeqeq */
            setSelectedSpell(spellList.find(spell => spell.breedId == sharedData.player.breedId));
        }
    }, [sharedData.player.breedId]);

    useEffect(() => {
        if (selectedSpell) {
            handleAttackChange(attackMap.get(selectedSpell.id)[0]);
        }
    }, [selectedSpell]);

    const handleAttackChange = (changedAttack) => {
        onPrefill(changedAttack);
    }

    const pickSpell = (e) => {
        /* eslint-disable-next-line eqeqeq */
        setSelectedSpell(spellList.find(spell => spell.id == e.target.value));
    }

    const pickAttack = (e) => {
        handleAttackChange(attackMap.get(selectedSpell.id)[e.target.value]);
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <h4>Preselect</h4>
            <select onChange={pickSpell}>
            /* eslint-disable-next-line eqeqeq */
                {
                    /* eslint-disable-next-line eqeqeq */
                    spellList.map(spell => (sharedData.player.breedId == spell.breedId || sharedData.player.breedId == -1) &&
                        (<option key={spell.id} value={spell.id}>
                            {spell.names.fr}
                        </option>)
                    )}
            </select>
            <select onChange={pickAttack}>
                {(attackMap.get(selectedSpell.id) ?? []).map((attack, index) => (
                    <option key={attack.id} value={index}>
                        {"Lvl " + attack.attackLevel}
                    </option>
                ))}
            </select>
        </div>
    );
}
/* eslint-enable */

SpellPicker.propTypes = {
    onPrefill: PropTypes.func.isRequired,
};

export default SpellPicker;
