import ItemSlot from "./ItemSlot";
import ProtagonistPart from "./ProtagonistPart";
import { useContext } from 'react';
import { DataContext } from "../common_use/DataContext";

function CenterPart() {
    const { sharedData } = useContext(DataContext);
    const buildItems = sharedData.build.items;

    return (
        <div className="center-part">
            <div className="center-part-top">
                <div className="item-bar-vertical">
                    <ItemSlot item={buildItems.get("helmet")} />
                    <ItemSlot item={buildItems.get("cape")} />
                    <ItemSlot item={buildItems.get("ring1")} />
                    <ItemSlot item={buildItems.get("weapon")} />
                    <ItemSlot item={buildItems.get("shield")} />
                </div>
                <div className="center">
                    <ProtagonistPart />
                </div>
                <div className="item-bar-vertical">
                    <ItemSlot item={buildItems.get("collar")} />
                    <ItemSlot item={buildItems.get("ring2")} />
                    <ItemSlot item={buildItems.get("belt")} />
                    <ItemSlot item={buildItems.get("shoe")} />
                    <ItemSlot item={buildItems.get("pet")} />
                </div>
            </div>
            <div className="center-part-bottom">
                <div className="item-bar-horizontal">
                    <ItemSlot item={buildItems.get("dofusPrysma")} />
                    <ItemSlot item={buildItems.get("dofus2")} />
                    <ItemSlot item={buildItems.get("dofus3")} />
                    <ItemSlot item={buildItems.get("dofus4")} />
                    <ItemSlot item={buildItems.get("dofus5")} />
                    <ItemSlot item={buildItems.get("dofus6")} />
                </div>
            </div>
        </div>
    );
}

export default CenterPart;