import Item from './Item';

class Build {
    static ITEM_TYPE_MAPPING = new Map([
        ["SlotArme", "weapon"],
        ["SlotAnneau1", "ring1"],
        ["SlotAnneau2", "ring2"],
        ["SlotAmulette", "collar"],
        ["SlotChapeau", "helmet"],
        ["SlotCape", "cape"],
        ["SlotCeinture", "belt"],
        ["SlotBottes", "shoe"],
        ["SlotBouclier", "shield"],
        ["SlotFamilierMonture", "pet"],
        ["SlotDofusPrysmaradite", "dofusPrysma"],
        ["SlotDofus2", "dofus2"],
        ["SlotDofus3", "dofus3"],
        ["SlotDofus4", "dofus4"],
        ["SlotDofus5", "dofus5"],
        ["SlotDofus6", "dofus5"],
    ]);

    static PLACE_HOLDERS_ITEMS = new Map([
        ["weapon", new Item(-1, "weapon", "Weapon placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_weapon_inventory.png"), {}],
        ["ring1", new Item(-1, "ring1", "Ring placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_ring_inventory.png"), {}],
        ["ring2", new Item(-1, "ring2", "Ring placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_ring_inventory.png"), {}],
        ["collar", new Item(-1, "collar", "Collar placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_collar_inventory.png"), {}],
        ["helmet", new Item(-1, "helmet", "Helmet placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_helmet_inventory.png"), {}],
        ["cape", new Item(-1, "cape", "Cape placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_cape_inventory.png"), {}],
        ["belt", new Item(-1, "belt", "Belt placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_belt_inventory.png"), {}],
        ["shoe", new Item(-1, "shoe", "Shoe placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_shoe_inventory.png"), {}],
        ["shield", new Item(-1, "shield", "Shield placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_shield_inventory.png"), {}],
        ["pet", new Item(-1, "pet", "Pet placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_pet_inventory.png"), {}],
        ["dofusPrysma", new Item(-1, "dofusPrysma", "DofusPrysma placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}],
        ["dofus2", new Item(-1, "dofus2", "Dofus2 placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}],
        ["dofus3", new Item(-1, "dofus3", "Dofus3 placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}],
        ["dofus4", new Item(-1, "dofus4", "Dofus4 placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}],
        ["dofus5", new Item(-1, "dofus5", "Dofus5 placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}],
        ["dofus6", new Item(-1, "dofus6", "Dofus6 placeholder", {}, 1, -1, "https://dofusdb.fr/icons/icon_slot_dofus_inventory.png"), {}]
    ]);

    constructor(
        items = new Map(),
        jsonResponse = "",
    ) {
        this.items = items;
        Build.PLACE_HOLDERS_ITEMS.forEach((item, itemType) => {
            (this.items.get(itemType) && this.items.get(itemType).name) || this.items.set(itemType, item);
        });
        this.jsonResponse = jsonResponse;
    }

    static fromRoxxBuilderFormatJSON(jsonResponse) {
        const { items } = jsonResponse;
        const itemsMap = new Map([...Build.ITEM_TYPE_MAPPING].map(([key, value]) => {
            return [value, new Item(items[key].id, items[key].value, items[key].name, items[key].names, items[key].level, items[key].setId, items[key].img, items[key].imgs)];
        }));
        return new Build(itemsMap, jsonResponse);
    }
}

export default Build;