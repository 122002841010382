import React from 'react';
import SubPartTitle from '../common_use/SubPartTitle';

const ItemsConditions = () => {
  return (
    <div className="build-conditions-items">
        <SubPartTitle title="Items Conditions" />
    </div>
  );
};

export default ItemsConditions;
