import React from 'react'
import SubPartTitle from '../common_use/SubPartTitle'
import BreedPicker from './BreedPicker'

function ProtagonistPart() {
    return (
        <div>
            <SubPartTitle title="You"/>
            <BreedPicker/>
        </div>
    )
}

export default ProtagonistPart
