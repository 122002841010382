class Item {
    constructor(id, itemType, name, names, level, setId, img, imgs) {
        this.id = id;
        this.itemType = itemType;
        this.name = name;
        this.names = names;
        this.level = level;
        this.setId = setId;
        this.img = img;
        this.imgs = imgs;
    }
}

export default Item;
