import SubPartTitle from "../common_use/SubPartTitle";

function Opponent() {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <SubPartTitle title="Opponent" />
            <p>
                [Comming eventually]
            </p>
        </div>
    )
}

export default Opponent;