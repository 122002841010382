import DamageLineStats from './DamageLineStats';

class Attack {
    constructor(damageLinesStats = [], id = -1, critChance = -1, isPoison = false, isTrap = false, isWeapon = false, isDistance = false, apCost = 1, attackLevel = 1) {
        this.damageLinesStats = damageLinesStats;
        this.id = id;
        this.critChance = critChance;
        this.isPoison = isPoison;
        this.isDistance = isDistance;
        this.isTrap = isTrap;
        this.isWeapon = isWeapon;
        this.apCost = apCost;
        this.attackLevel = attackLevel;
    }

    static ELEMENT_CODE = [
        ['terre', 1],
        ['feu', 2],
        ['eau', 3],
        ['air', 4],
        ['neutre', 0],
    ]

    static ELEMENT_MAP = new Map(this.ELEMENT_CODE);
    static CODE_ELEMENT_MAP = new Map(this.ELEMENT_CODE.map(([elem, code]) => ([code, elem])));

    toRoxxBuilderFormatJSON() {
        const json = JSON.stringify({
            damages: this.damageLinesStats.map(line => ({ damageElement: Attack.ELEMENT_MAP.get(line.element), minValue: Number(line.fromAmount), maxValue: Number(line.toAmount) })),
            id: this.id,
            critDamages: this.damageLinesStats.map(line => ({ damageElement: Attack.ELEMENT_MAP.get(line.element), minValue: Number(line.fromAmountCrit), maxValue: Number(line.toAmountCrit) })),
            damageSource: this.isWeapon ? "Arme" : "Sort",
            damagePosition: this.isDistance ? "Distance" : "Melee",
            piege: this.isTrap,
            canCrit: this.critChance > -1 ? true : false,
            baseCrit: this.critChance,
            damageCalculation: "Average",
        });
        return json;
    }

    static fromRoxxBuilderFormatJSON(spellList) {
        const attackMap = new Map();

        spellList.forEach(spell => {
            attackMap.set(spell.id,
                spell.attacks.map(jsonAttack => {
                    const damageLines = jsonAttack.damages.map(damageLine =>
                        new DamageLineStats(damageLine.minValue, damageLine.maxValue, "", "", this.CODE_ELEMENT_MAP.get(damageLine.damageElement))
                    );
                    jsonAttack.critDamages.forEach(critDamageLine => {
                        const foundLine = damageLines.find(line => line.element === this.CODE_ELEMENT_MAP.get(critDamageLine.damageElement) && line.fromAmountCrit === "" && line.toAmountCrit === "");
                        if (foundLine) {
                            foundLine.fromAmountCrit = critDamageLine.minValue;
                            foundLine.toAmountCrit = critDamageLine.maxValue;
                        } else {
                            damageLines.push(new DamageLineStats("", "", critDamageLine.minValue, critDamageLine.maxValue, this.CODE_ELEMENT_MAP.get(critDamageLine.damageElement)));
                        }
                    });
                    
                    return !damageLines.length > 0 ? null : new Attack(
                        damageLines,
                        jsonAttack.id,
                        jsonAttack.baseCrit,
                        jsonAttack.poison,
                        jsonAttack.piege,
                        jsonAttack.damageSource !== "Sort",
                        true,
                        jsonAttack.apCost,
                        jsonAttack.minPlayerLevel
                    );
                }).filter(attack => attack != null)
            )
        });
        return attackMap;
    }

    clone() {
        return new Attack(
            this.damageLinesStats.map(line => ({
                fromAmount: line.fromAmount,
                toAmount: line.toAmount,
                fromAmountCrit: line.fromAmountCrit,
                toAmountCrit: line.toAmountCrit,
                element: line.element
            })),
            this.id,
            this.critChance,
            this.isPoison,
            this.isTrap,
            this.isWeapon,
            this.isDistance,
            this.apCost,
            this.attackLevel,
        );
    }
}

export default Attack;
