import React from 'react'; // Importing Modules


function ElementPicker({element, setElement, name}) {

    return (
        <div className="element-picker">
            <select id="elements" value={element} onChange={setElement} className='element-picker-drop-down' name={name}>
                <option value="terre">Terre</option>
                <option value="feu">Feu</option>
                <option value="eau">Eau</option>
                <option value="air">Air</option>
                <option value="neutre">Neutre</option>
            </select>
        </div>
    );
};

export default ElementPicker;