import React from 'react';
import SubPartTitle from '../common_use/SubPartTitle';

const StatsConditions = () => {
  return (
    <div className="build-conditions-items">
        <SubPartTitle title="Stats target" />
    </div>
  );
};

export default StatsConditions;
