import ItemsConditions from "../build_specificities/ItemsConditions";
import StatsConditions from "../build_specificities/StatsConditions";

function RightPart() {
    return (
        <div className="side-part">
            <ItemsConditions/>
            <StatsConditions/>
        </div>
    );
}

export default RightPart;