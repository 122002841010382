import React, { useState } from "react";
import Navbar from "../nav_bar/Navbar";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import CenterPart from "../player_and_build/CenterPart";
import BottomPart from "../search_part/BottomPart";
import { DataContext } from "../common_use/DataContext";
import Attack from "../attack/Attack";
import Build from "../player_and_build/Build";
import "../../resources/style/main.scss";
import Player from "../player_and_build/Player";

function MainPage() {
    const [sharedData, setSharedData] = useState({
        attack: new Attack(),
        build: new Build(),
        opponent: {},
        player: new Player(),
    })

    return (
        <div className="main-page">
            <DataContext.Provider value={{ sharedData, setSharedData }}>
                <div className="nav-container">
                    <Navbar />
                </div>
                <div className="page-wrapper">
                    <div className="core-part">
                        <LeftPart />
                        <CenterPart />
                        <RightPart />
                    </div>
                    <div>
                        <BottomPart />
                    </div>
                </div>
            </DataContext.Provider >
        </div >
    )
}

export default MainPage;