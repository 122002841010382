import { React, useState, useEffect } from 'react';

import { useContext } from 'react';
import { DataContext } from "../common_use/DataContext";

const breedsDataURL = `${process.env.REACT_APP_BACKEND_URL}/api/breeds`;

function BreedPicker() {
    const [breeds, setBreeds] = useState([]);
    const { sharedData, setSharedData } = useContext(DataContext);


    useEffect(() => {
        const fetchBreeds = async () => {
            try {
                const response = await fetch(breedsDataURL);
                const responseJSON = await response.json();

                if (responseJSON.status === "succes") {
                    const data = responseJSON.data;
                    setBreeds(data);
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchBreeds().then()
    }, []);


    const handleChange = (e) => {
        setSharedData({ ...sharedData, player: { ...sharedData.player, breedId: e.target.value } });
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select onChange={handleChange}>
                <option key={-1} value={-1}>
                    Toutes
                </option>
                {breeds.map(breed => (
                    <option key={breed.id} value={breed.id}>
                        {breed.names.fr}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default BreedPicker;