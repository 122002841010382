import React from "react";
import PropTypes from "prop-types";
import Item from "./Item";

function ItemSlot({item}) {
    const imgPath = item.imgs && item.imgs[250] ? item.imgs[250]: item.img;

    return (
        <div className="item-slot">
            <img src={imgPath} alt="Item slot" className="item-image"/>
        </div>
    );
}

ItemSlot.propTypes = {
    item: PropTypes.instanceOf(Item).isRequired,
};

export default ItemSlot;
