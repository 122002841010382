import React from 'react';

const ProgressBar = ({ value }) => {
  return (
    <progress value={value} max="100" className="progress-bar">
      {`${value}%`}
    </progress>
  );
};

export default ProgressBar;
