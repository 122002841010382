import React from 'react';
import ElementPicker from './ElementPicker';
import DamageLineStats from './DamageLineStats';

function DamageLine({ id, stats, onAmountChange }) {
    const handleAmountChange = (e) => {
        const { name, value } = e.target;
        if (!value || value.match(/^\d{1,3}$/) || (name === 'element' && ['terre', 'feu', 'eau', 'air', 'neutre'].includes(value)) ) {
            const updatedStats = new DamageLineStats(
                name === 'fromAmount' ? value : stats.fromAmount,
                name === 'toAmount' ? value : stats.toAmount,
                name === 'fromAmountCrit' ? value : stats.fromAmountCrit,
                name === 'toAmountCrit' ? value : stats.toAmountCrit,
                name === 'element' ? value : stats.element
            );
            onAmountChange(id, updatedStats);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
            <input
                type="text"
                name="fromAmount"
                value={stats.fromAmount}
                onChange={handleAmountChange}
                className="num-input-field"
            />
            <span>&nbsp;</span>
            <input
                type="text"
                name="toAmount"
                value={stats.toAmount}
                onChange={handleAmountChange}
                className="num-input-field"
            />
            <span style={{ fontWeight: 'bold' }}>&nbsp;(</span>
            <input
                type="text"
                name="fromAmountCrit"
                value={stats.fromAmountCrit}
                onChange={handleAmountChange}
                className="num-input-field-crit"
            />
            <span>&nbsp;</span>
            <input
                type="text"
                name="toAmountCrit"
                value={stats.toAmountCrit}
                onChange={handleAmountChange}
                className="num-input-field-crit"
            />
            <span style={{ fontWeight: 'bold' }}>)&nbsp;</span>
            <ElementPicker element={stats.element} setElement={handleAmountChange} name="element"/>
        </div>
    );
}

export default DamageLine;