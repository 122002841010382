class DamageLineStats {
    constructor(fromAmount='', toAmount='', fromAmountCrit='', toAmountCrit='', element='terre') {
        this.fromAmount = fromAmount;
        this.toAmount = toAmount;
        this.fromAmountCrit = fromAmountCrit;
        this.toAmountCrit = toAmountCrit;
        this.element = element;
    }
}

export default DamageLineStats;
