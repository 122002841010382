import React from 'react';
import SearchBuildButton from './SearchBuildButton';

const BottomPart = () => {
  return (
    <div className="bottom-part">
        <SearchBuildButton text="Search Build"/>
    </div>
  );
}

export default BottomPart;
